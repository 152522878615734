/*
 * @Description:
 * @Author: Evan huang
 * @Date: 2021-07-19 14:34:06
 * @LastEditors: Evan huang
 * @LastEditTime: 2025-01-02 05:49:22
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/services/client/client.ts
 */
import { request } from '../../utils';

export function getCurrentClient(params) {
  return request.get('/api/client/user/me', params, false);
}

export function changeNickname(params) {
  return request.post('/api/client/user/nickname', params, false);
}

export function changeGender(params) {
  return request.post('/api/client/user/gender', params, false);
}

export function changeAvatar(params) {
  return request.post('/api/client/user/avatar', params, false);
}

export function applyMerchant(params) {
  return request.post('/api/client/apply', params, false);
}

export function upload(params) {
  return request.postForm('/api/client/upload', params, false);
}

export function sendCode(params) {
  return request.post('/api/client/user/send_code', params, false);
}
export function isApplyMerchant(params) {
  return request.get('/api/client/apply', params, false);
}
export function updatePhoneNumber(params) {
  return request.post('/api/client/user/verify', params, false);
}

export function getNotificationHistory(params) {
  return request.get('/api/client/notifications/all', params);
}

export function markNotificationAsRead(params) {
  return request.post(`/api/client/notifications/${params.id}/read`);
}
export function getArticleIdByTradeNo(params) {
  return request.get('/api/client/deposit/getArticleIdByPartyOrderId', params);
}

//查询比赛池关联的文章列表
export function getArticlesByRuleId(params) {
  return request.get('/api/client/articles/getArticlesByRuleId', params, false);
}

/** 获取多通道支付配置 */
export function getPayConfigs() {
  return request.get('/api/client/user/getAdapayVos');
}

//查询推广钱包提现费率
export function getWithdrawRatio() {
  return request.get(
    '/api/client/adapay-promotion-wallet-log/getWithdrawRatio',
  );
}

//获取充值说明文案
export function getPayDesc() {
  return request.get('/api/client/agreement/getPayDesc');
}

export function uploadBatch(params: any) {
  return request.postForm('/api/merchant/upload/batch', params, false);
}

//查询好友用户列表
export function getClientMessage(params: any) {
  return request.get(
    '/api/client/webSocketClient/getResultMessage',
    params,
    false,
  );
}

//我的邀请列表统计 myInviteUserStat
export function myInviteUserStat(params) {
  return request.get(
    '/api/client/userPromotion/myInviteUserStat',
    params,
    false,
  );
}

//获取平台聊天记录
export function getResultMessage(params: any) {
  return request.get(
    '/api/admin/webSocketClient/getResultMessage',
    params,
    false,
  );
}

//获取历史记录
export function getHistoryMessage(params: any) {
  return request.get(
    '/api/client/webSocketClient/getHistoryMessage',
    params,
    false,
  );
}

//获取类型
export function articleType(params: any) {
  return request.get('/api/client/articleType/pageList', params, false);
}

//客户端关系
export function saveWs(params: any) {
  return request.post('/api/client/webSocketClient/saveWs', params, false);
}

//用户钱包退款
export function userWalletRefund() {
  return request.post('/api/client/userWallet/userWalletRefund', false);
}

//
export function checkSubscribe(params: any) {
  return request.get('/api/client/wechat/gzh/checkSubscribe', params, false);
}

// 查询参与推广的商家
export function getPromotionMerchants(params) {
  return request.get(
    '/api/client/userPromotion/getPromotionMerchants',
    params,
    false,
  );
}

//套餐列表
export function getPlanByArticleId(params) {
  return request.post(
    '/api/client/articles-plan/getPlanByArticleId',
    params,
    false,
  );
}

//查询是否绑定银行卡
export function getUserBindBank() {
  return request.get('/api/client/user/getUserBindBank', false);
}

// 获取商家推广二维码
export function getMerchantsQRCode(params) {
  return request.get(
    '/api/client/userPromotion/getMerchantsQRCode',
    params,
    false,
  );
}

//获取支付通道
export function getAdapayVosByMerchantId(params: any) {
  return request.get(
    `/api/client/articles/getAdapayVosByMerchantId/${params.merchantId}`,
    { client: 1 }, //Client = 1等于H5支付通道
  );
}

//获取申请商户数量
export function getApplieTotal() {
  return request.get('/api/client/apply/getApplies', {}, false);
}

//获取我的优惠券列表
export function getMyCoupons(params: any) {
  return request.post('/api/client/couponUser/getMyCoupon', params, false);
}

export function getAvailableCoupons(params: any) {
  return request.get(
    '/api/client/couponUser/getAvailableCoupons',
    params,
    false,
  );
}

/** 获取优惠卡列表 */
export function getDiscountCardList(params: any) {
  return request.get('/api/client/discount/card', params);
}

/** 购买优惠卡 */
export function buyDiscountCard(params: any) {
  return request.post('/api/client/discount/card/orderPay', params);
}

/** 查询优惠卡订单 */
export function getDiscountCardOrder(params: any) {
  return request.get('/api/client/discount/card/orders', params);
}

//获取套餐详情-料列表
export function getPlanDetailPage(params: any) {
  return request.post(
    '/api/client/articles-plan/getPlanDetailPage',
    params,
    false,
  );
}

//收益明细列表
export function earningPageList(params) {
  return request.get(
    '/api/client/adapay-promotion-wallet-log/earningPageList',
    params,
    false,
  );
}
//日收益统计
export function withdrawPageList(params) {
  return request.get(
    '/api/client/adapay-promotion-wallet-log/withdrawPageByDay',
    params,
    false,
  );
}

/*
 * @Description: 点金计划-中转页面
 * @Author: Evan huang
 * @Date: 2024-12-24 05:19:14
 * @LastEditors: Evan huang
 * @LastEditTime: 2024-12-24 07:21:16
 * @Copyright: Hunan Sanxing Yunzhi Information Technology Co., Ltd.
 * @FilePath: /msll_h5/src/screens/PayForward.tsx
 */

import React from 'react';
import { Image, Text } from 'react-native';
import Flex from '@ant-design/react-native/lib/flex';
import BaseButton from '../components/BaseButton';
import image from '../utils/image';
import { colors } from '../utils';
import { navigate } from '../navigators/RootNavigation';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

const PayForward = () => {
  const route = useRoute();
  const nativation = useNavigation<StackNavigationProp>();
  const { trade_no } = route.params as any;
  return (
    <Flex direction="column" align="center" style={{ flex: 1 }}>
      <Text style={{ marginTop: '15%', fontSize: 20 }}>支付结果</Text>
      <Image source={image.WEIXIN_ICON} style={{ width: 100, height: 100 }} />
      <Text style={{ marginTop: 20, fontSize: 20, color: colors.RED_ALIZARIN }}>
        微信支付成功
      </Text>
      <BaseButton
        text="点击查看"
        style={{ marginTop: 20, width: '70%', borderRadius: 10 }}
        onPress={() => {
          nativation.replace('OrderDetail', { trade_no });
        }}
      />
      <BaseButton
        text="订单问题/投诉"
        style={{
          marginTop: 20,
          width: '50%',
          height: 20,
          maxHeight: 35,
          backgroundColor: 'transparent',
        }}
        textStyle={{ color: colors.DUSTY_GRAY }}
        onPress={() => {
          navigate('Suggest');
        }}
      />
    </Flex>
  );
};

export default PayForward;

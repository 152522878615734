import React, { useState } from 'react';
import {
  SafeAreaView,
  Text,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { colors } from '../../../utils';

import { useEffect } from 'react';

const { width: DEVICE_WIDTH } = Dimensions.get('window');

const FeedbackScreen = () => {
  const navigation = useNavigation();

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.content}>
        <View style={styles.body}>
          <Text style={styles.headerText}>内容</Text>
          {/* <MImageUpload
            before={
              <View style={styles.textInputSection}>
                <TextInput
                  returnKeyType="done"
                  placeholder="请输入内容"
                  value={text}
                  multiline={true}
                  onChangeText={(editText) => setText(editText)}
                  onSubmitEditing={() => Keyboard.dismiss()}
                  style={styles.textInput}
                />
              </View>
            }
            value={articleImages}
            max={7}
            type={'client'}
            onChange={setArticleImages}
            Props={{ ImageSlot: { size: 100 } }}
          /> */}
        </View>
        <TouchableOpacity
          style={styles.btnConfirm}
          onPress={() => navigation.goBack()}>
          <Text style={styles.btnConfirmText}>确认</Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  mainContainer: { flex: 1 },
  content: { backgroundColor: colors.WHITE, flex: 1 },
  body: {
    paddingHorizontal: 16,
    paddingTop: 17,
  },
  headerText: { color: colors.TUNDORA, fontSize: 16 },
  textInputSection: {
    marginTop: 16,
    backgroundColor: colors.GHOST_WHITE,
    borderColor: colors.SILVER,
    borderWidth: 0.5,
    borderRadius: 4,
    width: DEVICE_WIDTH - 32,
    height: 110,
    paddingHorizontal: 10,
    paddingTop: 5,
  },
  btnConfirm: {
    backgroundColor: colors.GUARDSMAN_RED,
    width: '100%',
    height: 50,
    position: 'absolute',
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnConfirmText: {
    color: colors.WHITE,
    fontSize: 18,
    fontWeight: '700',
  },
  textInput: {
    textAlignVertical: 'top',
    minHeight: 100,
  },
});

export default FeedbackScreen;

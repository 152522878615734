/*
 * @Author: Evan huang
 * @LastEditors: Evan huang
 * @Description: 公共导航组件
 */
import { createRef } from 'react';

export const navigationRef = createRef<any>();

export function navigate(name, params?) {
  navigationRef.current?.navigate(name, params);
}
export function replace(name, params?) {
  navigationRef.current?.replace(name, params);
}
export function goBack() {
  navigationRef.current?.goBack();
}

export function reset(name, params?) {
  navigationRef.current?.reset({
    index: 0,
    routes: [{ name, params }],
  });
}

/* eslint-disable react-native/no-inline-styles */
/*
 * @Author: your name
 * @Date: 2021-07-20 19:23:36
 * @LastEditTime: 2024-12-24 06:50:02
 * @LastEditors: Evan huang
 * @Description: In User Settings Edit
 * @FilePath: /msll_h5/src/screens/Suggest.tsx
 */
import React, { useEffect, useState } from 'react';
import { Picker, SafeAreaView, StyleSheet } from 'react-native';
import Flex from '@ant-design/react-native/lib/flex';
import List from '@ant-design/react-native/lib/list';
import InputItem from '@ant-design/react-native/lib/input-item';
import Button from '@ant-design/react-native/lib/button';
import Toast from '@ant-design/react-native/lib/toast';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'react-native';

const SuggestScreen = () => {
  const dispatch = useDispatch();
  const currentMerchant = useSelector(
    (state: any) => state.clientMerchant.currentMerchant,
  );
  const { id, name } = currentMerchant;
  const currentClient = useSelector((state: any) => state.client.currentClient);
  const { phoneNumber } = currentClient;

  const [content, setContent] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [typeValue, setTypeValue] = useState<string>();

  const onSubmit = () => {
    if (!typeValue || typeValue.trim().length === 0) {
      Toast.info('请选择投诉类型');
      return;
    }
    if (!content || content.trim().length === 0) {
      Toast.info('请填写投诉内容');
      return;
    }
    if (!phone || phone.trim().length !== 11) {
      Toast.info('请填写手机号');
      return;
    }

    Toast.loading('提交中', 0);
    //投诉完整内容：【商家信息ID-NAME】+投诉内容
    const contentAll =
      '[' + id + '-' + name + ']-[' + typeValue.trim() + ']' + content.trim();
    dispatch({
      type: 'client/submitSuggest',
      payload: { content: contentAll, phone: phone.trim() },
    });
  };

  useEffect(() => {
    phoneNumber && setPhone(phoneNumber);
  }, [phoneNumber]);

  return (
    <SafeAreaView style={styles.layout}>
      <Flex justify="center" style={styles.layout}>
        <List style={{ flex: 1 }}>
          <Text
            style={{
              marginHorizontal: 15,
              fontSize: 17,
              lineHeight: 50,
              color: '#D90000',
            }}>
            当前商家：{name}
          </Text>
          <Flex
            style={{
              height: 50,
              backgroundColor: '#FFF',
              borderTopWidth: 0.5,
              borderBottomWidth: 0.5,
              borderColor: '#999',
              marginHorizontal: 15,
            }}>
            <Text style={{ fontSize: 17, lineHeight: 50 }}>投诉类型</Text>
            {
              <Picker
                onValueChange={setTypeValue}
                style={{ flex: 1, height: 49, backgroundColor: '#FFF' }}
                itemStyle={{ height: 50 }}>
                <Picker.Item label="请选择投诉类型" value={undefined} />
                <Picker.Item label="支付成功未显示" value="支付成功未显示" />
                <Picker.Item label="商家做假、欺骗" value="商家做假、欺骗" />
                <Picker.Item
                  label="包含违法违规信息"
                  value="包含违法违规信息"
                />
                <Picker.Item label="包含谣言信息" value="包含谣言信息" />
                <Picker.Item label="平台意见建议" value="平台意见建议" />
              </Picker>
            }
          </Flex>
          <InputItem
            maxLength={200}
            onChange={setContent}
            placeholder="请填写相关内容详情">
            详细内容
          </InputItem>
          <InputItem
            onChange={setPhone}
            value={phone}
            placeholder="为方便核实请务必填写真实手机号">
            联系电话
          </InputItem>
          <Text
            style={{
              writingDirection: 'auto',
              textAlign: 'center',
              fontSize: 12,
              lineHeight: 20,
              color: '#999',
              marginHorizontal: 5,
            }}>
            本平台处理商家违规/虚假内容举报(不含推测概率内容,请注意甄别)，也欢迎大家提出建议，一起共同监督！
          </Text>
        </List>

        <Button
          style={{ width: '100%', backgroundColor: '#D90000' }}
          onPress={onSubmit}>
          <Text style={{ color: '#FFF' }}>提交投诉</Text>
        </Button>
      </Flex>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  layout: { flex: 1, flexDirection: 'column', alignItems: 'stretch' },
  tipText: {
    color: '#444',
    fontSize: 20,
    fontWeight: '800',
  },
});

export default SuggestScreen;
